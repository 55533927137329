// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inicio-atu-detalle-js": () => import("./../../../src/pages/inicio/atu/detalle.js" /* webpackChunkName: "component---src-pages-inicio-atu-detalle-js" */),
  "component---src-pages-inicio-atu-js": () => import("./../../../src/pages/inicio/atu.js" /* webpackChunkName: "component---src-pages-inicio-atu-js" */),
  "component---src-pages-inicio-certificados-certificados-de-reclamos-js": () => import("./../../../src/pages/inicio/certificados/certificados-de-reclamos.js" /* webpackChunkName: "component---src-pages-inicio-certificados-certificados-de-reclamos-js" */),
  "component---src-pages-inicio-certificados-certificados-rnpi-js": () => import("./../../../src/pages/inicio/certificados/certificados-rnpi.js" /* webpackChunkName: "component---src-pages-inicio-certificados-certificados-rnpi-js" */),
  "component---src-pages-inicio-certificados-js": () => import("./../../../src/pages/inicio/certificados.js" /* webpackChunkName: "component---src-pages-inicio-certificados-js" */),
  "component---src-pages-inicio-cotizaciones-js": () => import("./../../../src/pages/inicio/cotizaciones.js" /* webpackChunkName: "component---src-pages-inicio-cotizaciones-js" */),
  "component---src-pages-inicio-editar-perfil-js": () => import("./../../../src/pages/inicio/editar-perfil.js" /* webpackChunkName: "component---src-pages-inicio-editar-perfil-js" */),
  "component---src-pages-inicio-faq-js": () => import("./../../../src/pages/inicio/faq.js" /* webpackChunkName: "component---src-pages-inicio-faq-js" */),
  "component---src-pages-inicio-js": () => import("./../../../src/pages/inicio.js" /* webpackChunkName: "component---src-pages-inicio-js" */),
  "component---src-pages-inicio-lista-notificaciones-js": () => import("./../../../src/pages/inicio/lista-notificaciones.js" /* webpackChunkName: "component---src-pages-inicio-lista-notificaciones-js" */),
  "component---src-pages-inicio-reclamos-antecedentes-ingresar-antecedentes-js": () => import("./../../../src/pages/inicio/reclamos/antecedentes/ingresar-antecedentes.js" /* webpackChunkName: "component---src-pages-inicio-reclamos-antecedentes-ingresar-antecedentes-js" */),
  "component---src-pages-inicio-reclamos-antecedentes-js": () => import("./../../../src/pages/inicio/reclamos/antecedentes.js" /* webpackChunkName: "component---src-pages-inicio-reclamos-antecedentes-js" */),
  "component---src-pages-inicio-reclamos-js": () => import("./../../../src/pages/inicio/reclamos.js" /* webpackChunkName: "component---src-pages-inicio-reclamos-js" */),
  "component---src-pages-inicio-reclamos-recurso-js": () => import("./../../../src/pages/inicio/reclamos/recurso.js" /* webpackChunkName: "component---src-pages-inicio-reclamos-recurso-js" */),
  "component---src-pages-inicio-reclamos-recurso-presentar-recurso-js": () => import("./../../../src/pages/inicio/reclamos/recurso/presentar-recurso.js" /* webpackChunkName: "component---src-pages-inicio-reclamos-recurso-presentar-recurso-js" */),
  "component---src-pages-inicio-tramites-detalle-agregar-antecedentes-js": () => import("./../../../src/pages/inicio/tramites/detalle/agregar-antecedentes.js" /* webpackChunkName: "component---src-pages-inicio-tramites-detalle-agregar-antecedentes-js" */),
  "component---src-pages-inicio-tramites-detalle-agregar-recurso-js": () => import("./../../../src/pages/inicio/tramites/detalle/agregar-recurso.js" /* webpackChunkName: "component---src-pages-inicio-tramites-detalle-agregar-recurso-js" */),
  "component---src-pages-inicio-tramites-detalle-js": () => import("./../../../src/pages/inicio/tramites/detalle.js" /* webpackChunkName: "component---src-pages-inicio-tramites-detalle-js" */),
  "component---src-pages-inicio-tramites-js": () => import("./../../../src/pages/inicio/tramites.js" /* webpackChunkName: "component---src-pages-inicio-tramites-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-validacion-js": () => import("./../../../src/pages/validacion.js" /* webpackChunkName: "component---src-pages-validacion-js" */)
}

